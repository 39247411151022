import clsx from "clsx";
import { motion } from "framer-motion";
import { shared } from "src/content/shared";
interface IHeroCta {
  href: string;
  className?: string;
}

const HeroCta = ({ href, className }: IHeroCta) => {
  return (
    <motion.a
      className={clsx(
        "flex justify-center items-center max-lg:!max-w-[20.625rem] max-lg:!min-h-[3.5rem] font-geDinarone !text-white-w1 text-h3 max-lg:text-h4",
        className
      )}
      style={{ boxShadow: "2px 0px 32px 0px rgba(109, 255, 185, 0.30)" }}
      href={href}
      whileHover={{
        boxShadow: "2px 0px 40px 18px rgba(109, 255, 185, 0.30)",
      }}
      transition={{ type: "tween", stiffness: 400, damping: 10 }}
    >
      {shared.callToAction}
    </motion.a>
  );
};

export default HeroCta;
